.user_mode {
    #displayBodyHeader,
    #topNav,
    #topNavPlaceholder {
        display: none;
    }

    #siteContainer,
    .snFooterContainer {
        background: none;
    }
}

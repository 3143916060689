.user_mode {
    .heroPhotoElement {
        img {
            border: 0;
        }
    }

    .scottsdale .heroPhotoElement {
        border-right: 5px solid $hover-color;
    }

    .chandler .heroPhotoElement {
        border-right: 5px solid $accent-color;
    }

    #siteFooter a {
        color: $hover-color;
    }

    .hero .textBlockElement .text p span {
        font-size: em(20);
        font-weight: 300;
        font-family: $fontThree;
        color: $hover-color;
        border-bottom: 1px solid $accent-color;
        padding-bottom: 7px;
        letter-spacing: .1em;
        line-height: 48px;
        letter-spacing: .1em;
    }

    .hero .textBlockElement .text h1 {
        font-size: em(48);
        font-family: $fontOne;
        font-weight: 900;
        color: #fff;
        letter-spacing: .05em;
    }

    .hero .text {
        padding-top: 10%;
    }
}
